.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.analysis-footer {
  padding: 2.5rem 0;
  color: #999;
  text-align: center;
  background-color: #212529;
  border-top: 1px solid rgba(255,255,255,.125);
}
.accordion-button,
.accordion-button:not(.collapsed){
  color:#e9ecef;
  background-color: transparent;
  box-shadow: none;
  padding:0;
}
.accordion-button:not(.collapsed)::after{
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23A0A0A0'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e")
}
.accordion-button::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23A0A0A0'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e")
}
.accordion-button{
  color:#e9ecef;

}
@media (max-width: 600px) { /*Small Screen*/
  
  .card {
      margin-left: -15px;
      margin-right: -15px;
  }
  .card-no-grow {
    margin-left: 0px;
    margin-right: 0px;
  }
  .card-body{
      padding-left: 5px !important;
      padding-right: 5px !important;
  }
  td{
      padding-left: 4px !important;
      padding-right: 4px !important;
      font-size: small !important;
  }
  th{
      padding-left: 4px !important;
      padding-right: 4px !important;
      font-size: x-small !important;
  }
  .filter-btn {
      padding: 2px !important;
  }
  .badge {
      /* font-size: xx-small !important; */
      /* font-weight: normal !important; */
      white-space: inherit  !important;
  }
  /* .overflow-auto{
      margin-right: 35px;
  } */
  #factionSelection,
  #commanderSelection,
  #tournamentSelection,
  #playerSelection,
  #cardSelection,
  #opposingCardSelection {
      padding-right:35px;
  }
}
@media (min-width: 1570px){
  #statistics-card-body{
      max-height: 675px;
      overflow: auto;
  }
  .col-xxl-1 { flex: 0 0 8% !important; max-width: 8% !important; }
  .col-xxl-2 { flex: 0 0 16% !important; max-width: 16% !important; }
  .col-xxl-3 { flex: 0 0 25% !important; max-width: 25% !important; }
  .col-xxl-4 { flex: 0 0 33% !important; max-width: 33% !important; }
  .col-xxl-5 { flex: 0 0 41% !important; max-width: 41% !important; }
  .col-xxl-6 { flex: 0 0 50% !important; max-width: 50% !important; }
  .col-xxl-7 { flex: 0 0 58% !important; max-width: 58% !important; }
  .col-xxl-8 { flex: 0 0 66% !important; max-width: 66% !important; }
  .col-xxl-9 { flex: 0 0 75% !important; max-width: 75% !important; }
  .col-xxl-10 { flex: 0 0 83% !important; max-width: 83% !important; }
  .col-xxl-11 { flex: 0 0 91% !important; max-width: 91% !important; }
  .col-xxl-12 { flex: 0 0 100% !important; max-width: 100% !important; }
}

button:disabled {
  cursor: not-allowed;
  pointer-events: all !important;
}
span.react-datepicker__navigation-icon::before {
  top: 12px;
}
button.react-datepicker__close-icon::after{
  background-color: #e74c3c;
  font-weight: bolder;
}
@media (prefers-color-scheme: dark) {
  .form-select {
    background-color: #545b62;
    color: #ced4da;
    border: 1px solid rgba(255,255,255,.125);
  }
  
  .react-select__contrl:active {
    background-color: #222 !important;
  }
  .react-select__control {
    background-color: #181818 !important;
    border: 1px solid #515151 !important;
    color: #b1b1b1;
    box-shadow:none !important;
  }
  .react-select__control:hover{
    background-color: transparent !important;
    border: 1px solid #ffffffA0 !important;
  }
  .react-select__menu {
    background-color: #181818 !important;
    color: #b1b1b1;

  }
  .react-select__single-value {
    color: #b1b1b1 !important;
  }
  .react-select__option:hover,
  .react-select__option:focus,
  .react-select__option:active{
    background-color: #222 !important;
  }
}
/* .react-select__value-container,
.react-select__input-container,
.css-1s2u09g-control,
.css-1pahdxg-control:hover{
  background-color: transparent !important;
} */
/* .css-1pahdxg-control:hover{
  background-color: transparent !important;
  border: 1px solid #515151 !important;
} */
.profile-pic:hover{
  outline: 3px solid #CCC;
}
.header {
  border-bottom: #375a7f solid 3px;
  font-weight: bold;
}

@media (prefers-color-scheme: dark) {
  .draggablePlayer:hover {
    outline: #61dafb solid 1px;
    cursor: move;
    color: #61dafb
  }
  
  a.selfUserLink {
    color: #0dcaf0;
    font-weight: bold;
  }
}
@media (prefers-color-scheme: light) {
  .draggablePlayer:hover {
    outline: rgb(0, 60,137) solid 1px;
    cursor: move;
    color: rgb(0, 60,137)
  }
  
  a.selfUserLink {
    color: #0ca2c0;
    font-weight: bold;
  }
}

.editing {
  position: relative;
}
@media (prefers-color-scheme: dark) {
  /* .editing {
    outline: #61dafb solid 2px;
  } */
  .editing:before {
    content: '';
    width: .5em;
    background: #61dafb;
    position: absolute;
    left: 0; top: 0; bottom: 0;
    transform: translateX(-200%);
    outline: #61dafb solid 2px;
  }
}
@media (prefers-color-scheme: light) {
  /* .editing {
    outline: rgb(0, 60,137) solid 2px;
  } */
  .editing:before {
    content: '';
    width: .5em;
    background: rgb(0, 60,137);
    position: absolute;
    left: 0; top: 0; bottom: 0;
    transform: translateX(-200%);
    outline: rgb(0, 60,137) solid 2px;
  }
}
.footer-divider {
  height: 3rem;
  background-color: rgba(0, 0, 0, .1);
  border: solid rgba(0, 0, 0, .15);
    border-top-width: medium;
    border-right-width: medium;
    border-bottom-width: medium;
    border-left-width: medium;
  border-width: 1px 0;
  box-shadow: inset 0 .5em 1.5em rgba(0, 0, 0, .1), inset 0 .125em .5em rgba(0, 0, 0, .15);
}
.match-summary-rows {
  background-color: rgba(0, 0, 0, .1);
  /* border: solid rgba(0, 0, 0, .15);
  border-width: 1px 0; */
  box-shadow: inset 0 .5em 1.5em rgba(0, 0, 0, .1), inset 0 .125em .5em rgba(0, 0, 0, .15);
}