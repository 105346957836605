body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.tournament-link {
  font-weight: bold;
  text-decoration: none;
}

a {
  text-decoration: none;
}
@media (prefers-color-scheme: dark) {
  input:disabled {
    color: #333;
    border-color: #333;
  }
  input.form-control {
    background-color: #181818;
  }
  .roundVerified {
    background-color: #203040;
    color: white;
  }
  .roundConflict {
    background-color: #402030
  }
  .roundWin {
    background-color: #203040
  }
  .roundLoss {
    background-color: #402030
  }
  .roundDraw {
    background-color: #303030
  }
  .text-info2 {
    color: #0dcaf0;
  }
  .header {
    background-color: #333;
  }
}
@media (prefers-color-scheme: light) {
  .roundVerified {
    background-color: #DFEFFF
  }
  .roundConflict {
    background-color: #FFDFEF
  }
  .roundWin {
    background-color: #DFEFFF
  }
  .roundLoss {
    background-color: #FFDFEF
  }
  .roundDraw {
    background-color: #DDDDDD
  }
  .text-info2 {
    color: #0ca2c0;
  }
  
  .header {
    background-color: #DDD;
  }
}

.homeIcon {
  height:60px;
  width:60px;
  font-size: x-small;
}
div.paddedLikeInput {
  padding: .375rem .75rem
}
.roundRow {
  border-bottom: #375a7f dotted 1px;
}
.col-r-border {
  border-right: #375a7f dotted 1px;
}
.accordion-row{
  display: flex;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out,border-radius .15s ease;
  cursor: pointer;
}
.accordion-row::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23A0A0A0%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e");
}
.accordion-row:not(.collapsed)::after{
    background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23A0A0A0%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e");
}
.withdrawn {
  color: #808080;
  text-decoration: line-through;
}
.collapsible {
  position: relative;
  cursor: pointer;
}
.collapsible:after {
  content: '\02795'; /* Unicode character for "plus" sign (+) */
  font-size: 13px;
  color: white;
  /* float: right;
  margin-left: 5px; */
  position:absolute;
  left: -15px;
}

.collapsible.active:after {
  content: "\2796"; /* Unicode character for "minus" sign (-) */
}
.collapsible-content{
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s ease-out;
}
.ps-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
html {
  position: relative;
  min-height: 100%;
}
.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 100px;
  line-height: 10px;
  /* background-color: #f5f5f5; */
}
body {
  margin-bottom: 100px;
}
.overflow-ellipsis{
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
@media (min-width: 768px){
  .d-md-rightside {
    position:absolute;
    right:-20px;
  }
}
.swapping {
  animation: swapping 1s ease-out;
  animation-iteration-count: infinite; 
  opacity: 0.2;
  color: #f39c12
}
@keyframes swapping {
    0% { 
        opacity: 0.2;
    }
    50% { 
        opacity: 1.0;
    }
    100% { 
        opacity: 0.2;
    }
}
.listFactionIcon {
  opacity: 0.1;
  position:absolute;
  user-select: none;
  pointer-events: none;
  height: 400px;
  width: 400px;
}
.listFactionIconWrapper{
  position:absolute;
  top: 55px;
  height: calc(100% - 55px);;
  width: 100%;
  overflow: hidden;
  /* Magic */
  display: flex;
  /*align-items: center;*/ /* vertical */
  justify-content: center; /* horizontal */
  
  user-select: none;
  pointer-events: none;
}
.listFactionIconWrapperModal{
  position:absolute;
  top: 0px;
  width: 100%;
  height: 100%;
  overflow: hidden;
  /* Magic */
  display: flex;
  /*align-items: center;*/ /* vertical */
  justify-content: center; /* horizontal */
  pointer-events: none;
}
.ladderFactionIconSpacer{
  width: 20px;
  display: inline-block;
}